<template>
  <div id='user-view'>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback2 />
        {{ $t('summary_of_sales') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model='typeSelected'
            :items='typeList'
            dense
            outlined
            :label="$t('type')"
            item-text='name'
            item-value='id'
            @change='addPayload(payload.start,payload.end)'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='branchSelection'
            :items='branchList'
            outlined
            @change='addPayload(payload.start,payload.end)'
            dense
            :label="$t('select_branch')"
            item-text='shop_name'
            :no-data-text="$t('no_data')"
            item-value='shop_id_pri'
          ></v-select>

        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='searchType'
            :items='searchTypeList'
            outlined
            @change='addPayload(payload.start,payload.end)'
            dense
            :label="$t('choose_type')"
            item-text='text'
            :no-data-text="$t('no_data')"
            item-value='value'
          ></v-select>
        </v-col>
      </v-row>
      <DateFilters @onSendDate='addPayload' />
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-header
        hide-default-footer
        item-key='number'
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:header>
          <tr>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 150px'>#</th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 200px'>{{ $t('branch')
              }}
            </th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 200px'>
              {{ $t('buyDate') }}
            </th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 200px'>
              {{ $t('HN_Number') }}
            </th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 150px'>
              {{ $t('name') }}
            </th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 150px'>
              {{ $t('lastname') }}
            </th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 150px'>
              {{ $t('receipt_number') }}
            </th>
            <th rowspan='2' class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('sales') }}
            </th>
            <th rowspan='2' class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('discount') }}
            </th>
            <th rowspan='2' class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('point') }}
            </th>
            <th rowspan='2' class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('total_income') }}
            </th>
            <th rowspan='2' class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('owe') }}
            </th>
            <th colspan='5' class='text-center px-2 vertical'>{{ $t('courseDetailIsBuy') }}</th>
            <th rowspan='2' class='text-left px-2 vertical' style='min-width: 190px'>
              {{ $t('dateIsMember') }}
            </th>
          </tr>
          <tr>
            <th class='text-left px-2 vertical' style='min-width: 200px'>{{ $t('category')
              }}
            </th>
            <th class='text-left px-2 vertical' style='min-width: 130px'>
              {{ $t('code') }}
            </th>
            <th class='text-left px-2 vertical' style='min-width: 400px'>
              {{ $t('listName') }}
            </th>
            <th class='text-right px-2 vertical' style='min-width: 150px'>
              {{ $t('amount') }}
            </th>
            <th class='text-left px-2 vertical' style='min-width: 150px'>
              {{ $t('unit') }}
            </th>
          </tr>
        </template>
        <template v-slot:[`item.order_create`]='{ item }'>
          {{ item.order_create | sumdatetime }}
        </template>
        <template v-slot:[`item.customer_create`]='{ item }'>
          {{ item.customer_create | sumdatetime }}
        </template>
        <template v-slot:[`item.number`]='{ item }'>
              <span v-if='item.order_id === $t(`total`)'>
              </span>
          <span v-else>
            {{ item.number }}
          </span>
        </template>
        <template v-slot:[`item.order_id`]='{ item }'>
          <router-link
            :class="item.order_id === $t('total') ? 'font-weight-bold primary--text' : ''"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-bold text-decoration-none'
            target='_blank'
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_total`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.order_total }}
              </span>
        </template>
        <template v-slot:[`item.order_pointsave`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.order_pointsave }}
              </span>
        </template>
        <template v-slot:[`item.order_save_amount`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.order_save_amount }}
              </span>
        </template>
        <template v-slot:[`item.order_totalpay`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.order_totalpay }}
              </span>
        </template>
        <template v-slot:[`item.orderpay_balance`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_balance }}
              </span>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import branch from '@/api/branch'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { salessummary2, removeComma } from '../useExcel2'
import { sumdate, sumdatetime, formatPrice, removeFormatPrice } from '@/plugins/filters'
import Comeback2 from '../Comeback2.vue'
import XLSX from 'xlsx'
import store from '@/store/app'

export default {
  components: {
    DateFilters,
    Comeback2,
  },
  filters: {
    removeFormatPrice,
    sumdatetime,
    formatPrice,
  },
  setup() {
    const XLSX = require('xlsx')
    const courseGroupSelect = ref('')
    const typeSelected = ref(1)
    const typeList = ref([
      { id: 1, name: 'เพิ่มลูกค้าเมื่อ' },
      { id: 2, name: 'ชำระเงินเมื่อ' },
    ])
    const courseGroupList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50, align: 'left vertical' },
      { text: '#', value: 'shop_name', width: 50, align: 'left vertical' },
      { text: '#', value: 'order_create', width: 50, align: 'left vertical' },
      { text: '#', value: 'customer_id', width: 50, align: 'left vertical' },
      { text: '#', value: 'customer_fname', width: 50, align: 'left vertical' },
      { text: '#', value: 'customer_lname', width: 50, align: 'left vertical' },
      { text: '#', value: 'order_id', width: 50, align: ' left vertical' },
      { text: '#', value: 'order_total', width: 50, align: 'end vertical' },
      { text: '#', value: 'order_save_amount', width: 50, align: 'end vertical' },
      { text: '#', value: 'order_pointsave', width: 50, align: 'end vertical' },
      { text: '#', value: 'order_totalpay', width: 50, align: 'end vertical' },
      { text: '#', value: 'orderpay_balance', width: 50, align: 'end vertical' },
      { text: '#', value: 'orderdetail_group', width: 50, align: 'start vertical' },
      { text: '#', value: 'orderdetail_id', width: 50, align: 'start vertical' },
      { text: '#', value: 'orderdetail_name', width: 50, align: 'start vertical' },
      { text: '#', value: 'orderdetail_amount', width: 50, align: 'end vertical' },
      { text: '#', value: 'orderdetail_unit', width: 50, align: 'start vertical' },
      { text: '#', value: 'customer_create', width: 50, align: 'start vertical' },
    ])
    const columns2 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list'), value: 'shop_bank_name' },
      { text: i18n.t('total_'), value: 'orderpay_current', align: 'end' },
    ])
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const currentTab = ref(null)
    const header = ref('')
    const payload = ref({})
    const exportLoading = ref(false)
    const branchList = ref([])
    const branchSelection = ref('')
    const searchType = ref('')
    const searchTypeList = ref([
      { text: i18n.t('all'), value: '' },
      { text: i18n.t('course'), value: '1' },
      { text: i18n.t('drug'), value: '2' },
      { text: i18n.t('check'), value: '3' },
      { text: i18n.t('Member Card'), value: '4' },
    ])

    branch.branchListForSelection().then(res => {
      branchSelection.value = res[0].shop_id_pri
      branchList.value = res
    }).catch(error => {
      console.log('error : ', error)
    })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        main_shop_id_pri: branchSelection.value,
        type: searchType.value,
        sort: typeSelected.value,
        lang: i18n.locale,
      }

      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const {
        data,
      } = await salessummary2(payload)
      dataTableList.value = data
      header.value = `${i18n.t('summary_of_sales')} ${i18n.t('detail_at_day')} ${i18n.t('since')} ${sumdate(
        start,
      )} ${i18n.t('to')} ${sumdate(end)}`
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${header.value}.xlsx`
      const Heading = [
        [header.value],
        [
          `#`,
          `${i18n.t('branch')}`,
          `${i18n.t('buyDate')}`,
          `${i18n.t('HN_Number')}`,
          `${i18n.t('name')}`,
          `${i18n.t('lastname')}`,
          `${i18n.t('receipt_number')}`,
          `${i18n.t('total')}`,
          `${i18n.t('discount')}`,
          `${i18n.t('point')}`,
          `${i18n.t('total_income')}`,
          `${i18n.t('owe')}`,
          `${i18n.t('courseDetailIsBuy')}`,
          ``,
          ``,
          ``,
          ``,
          `${i18n.t('dateIsMember')}`,
        ],
        [
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          `${i18n.t('category')}`,
          `${i18n.t('code')}`,
          `${i18n.t('listName')}`,
          `${i18n.t('amount')}`,
          `${i18n.t('unit')}`,
          ``,
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      const mapList = dataTableList.value.map(item => {
        item.number = item.order_id === i18n.t('total') ? '' : item.number
        delete item.order_id_pri
        return {
          ...item,
        }
      })
      XLSX.utils.sheet_add_json(ws, await removeComma(JSON.parse(JSON.stringify(dataTableList.value))), {
        header: [
          'number',
          'shop_name',
          'order_create',
          'customer_id',
          'customer_fname',
          'customer_lname',
          'order_id',
          'order_total',
          'order_save_amount',
          'order_pointsave',
          'order_totalpay',
          'orderpay_balance',
          'orderdetail_group',
          'orderdetail_id',
          'orderdetail_name',
          'orderdetail_amount',
          'orderdetail_unit',
          'customer_create',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
        { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 2, c: 8 } },
        { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
        { s: { r: 1, c: 10 }, e: { r: 2, c: 10 } },
        { s: { r: 1, c: 11 }, e: { r: 2, c: 11 } },
        { s: { r: 1, c: 12 }, e: { r: 1, c: 16 } },
        { s: { r: 1, c: 17 }, e: { r: 2, c: 17 } },
      ]
      const wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('summary_of_sales')}`)
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      exportLoading,
      courseGroupSelect,
      columns,
      dataTableList,
      loading,
      options,
      payload,
      courseGroupList,
      columns2,
      currentTab,
      branchSelection,
      branchList,
      typeSelected,
      searchType,
      searchTypeList,
      typeList,
      mdiFileExcelOutline,
      store,
      removeFormatPrice,
      addPayload,
      exportExcel,
    }
  },
}
</script>
<style lang='scss'>
.vertical {
  border-left: 1px solid rgba(50, 50, 50, 0.14);
  height: 35px;
}

.theme--light.v-data-table th {
  border-top: thin solid rgba(50, 50, 50, 0.14);
  color: rgba(50, 50, 50, 0.87) !important;
  border-bottom: thin solid rgba(50, 50, 50, 0.14);
}

@import '@core/preset/preset/apps/user.scss';
</style>
